.App {
  font-family: Poppins !important;
  font-size: 14px;
}

.meter_reports_container {
  background-color: #F5F5F5;
  height: 87vh;
  padding: 20px;
  margin-top: 10vh;
  margin-left: 60px;
  font-size: 14px;
}


.meter_container_reports {
  background-color: #F5F5F5;
  height: 87vh;
  padding: 20px;
  margin-top: 10vh;
  margin-left: 60px;
  font-size: 14px;
}


.commercial-head-add-facility-container {
  margin-left: 60px;
  margin-top: 10vh;
  padding: 20px;
  background-color: #F5F5F5;
  height: 87vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.commercial-head-add-facility-container h1 {
  font-size: 36px;
}

.commercial-head-add-facility-container .commercial-head-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.commercial-head-facilities-table {
  width: 100%;
  height: 67%;
  overflow: auto;
  font-size: 14px;
}

.commercial-head-facilities-table table {
  width: 100%;
}

.commercial-head-facilities-table table thead {
  position: sticky;
  top: 0px;
  border-bottom: 1px solid #CDCDCC;
  background: #EDEDED;
  box-shadow: 2px 2px 22px 0px rgba(0, 0, 0, 0.10);
}

.commercial-head-facilities-table table thead tr th {
  padding: 10px 20px;
}

.commercial-head-facilities-table table tbody tr {
  border-bottom: 1px solid #CDCDCC;
  background: var(--color-gray-10, #FFF);
}

.commercial-head-facilities-table table tbody tr td {
  padding: 10px 20px;
}


.table_container_facility_manager {
  width: 100%;
  height: 60%;
  overflow: auto;
  font-size: 14px;
}

.table_container_facility_manager table {
  width: 100%;
}

.facility_container {
  background-color: #F5F5F5;
  height: 87vh;
  padding: 20px;
  margin-top: 10vh;
  margin-left: 60px;
  font-size: 14px;
}

.meter_reports_table {
  width: 100%;
  height: 310px;
  overflow: auto;
  font-size: 14px;
}


.fin_meter_reports_table {
  width: 100%;
  height: 310px;
  overflow: auto;
  font-size: 14px;
  margin-top: 20px;
}


.fin_meter_reports_table {
  width: 100%;
  height: 72%;
  overflow: auto;
  font-size: 14px;
}

.fin_meter_reports_table table {
  width: 100%;
}

.table_container_finance_manager {
  width: 100%;
  height: 62%;
  overflow: auto;
  font-size: 14px;
}

.table_container_finance_manager table {
  width: 100%;
}

.invoice_container {
  background-color: #F5F5F5;
  height: 87vh;
  padding: 20px;
  margin-top: 10vh;
  margin-left: 60px;
  font-size: 14px;
}


.table_container_commercial_head {
  width: 100%;
  height: 72%;
  overflow: auto;
  font-size: 14px;
  /* margin-top: 50px; */
}

.table_container_commercial_head table {
  width: 100%;
}

.payments_container {
  background-color: #F5F5F5;
  min-height: 90vh;
  padding-left: 20px;
  margin-top: 10vh;
  margin-left: 60px;
  font-size: 13px;
}

.fin_man_meter_label {
  color: #706F6F;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.fin_man_meter_dropdown {
  padding: 10px;
  /* padding-right: 80px; */
  border-radius: 6px;
  border: 0.5px solid var(--Textbox, #D3D3D3);
  background: var(--color-gray-10, #FFF);
}

.fin_man_meter_search_bar_container {
  position: relative;
}

.our_companies_search_bar_container {
  position: relative
}

.fin_man_meter_search_bar_icon {
  position: absolute;
  top: 17px;
  right: 15px;
}

.fin_man_meter_search_bar {
  padding: 12px 16px;
  border-radius: 8px;
  padding-right: 30px;
  border: 1px solid var(--Textbox, #D3D3D3);
  background: var(--color-gray-10, #FFF);
  outline: none;
}

@media screen and (max-width : 377px) {
  .fin_man_meter_modal_label {
    font-size: 12px;
  }
}


.fin_man_model_meter_dropdown {
  padding: 7px 60px 7px 10px;
  border-radius: 6px;
  width: 250px;
  border: 0.5px solid #9D9D9C;
  background-color: var(--color-gray-10, #FFF);
}


@media screen and (max-width : 990px) {
  .fin_man_model_meter_dropdown {
    padding: 4px 60px 4px 10px;
    border-radius: 6px;
    width: 150px;
    border: 0.5px solid #9D9D9C;
    background-color: var(--color-gray-10, #FFF);
  }
}


@media screen and (max-width : 500px) {
  .fin_man_model_meter_dropdown {
    padding: 4px 10px 4px 10px;
    border-radius: 6px;
    width: 100px;
    border: 0.5px solid #9D9D9C;
    background-color: var(--color-gray-10, #FFF);
  }
}

.fin_man_meter_input_fields {
  position: relative;
  width: fit-content;
}


.kwh {
  position: absolute;
  right: 10px;
  top: 6px;
  font-weight: 660;
}

.invoice_btn {
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid var(--Textbox, #D3D3D3);
  background-color: #B2B2B2;
}


.add_fac_apply_btn {
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Textbox, #D3D3D3);
  background-color: #B2B2B2;
}


.add_fac_apply_btn:disabled {
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Textbox, #D3D3D3);
  background-color: #D3D3D3;
}

.fin_man_date_range {
  padding: 8px;
  border-radius: 8px;
  border: 0.01px solid var(--Grey-1, #B2B2B2);
  background: #FFF;
  /* outline: none; */
}

.fin_man_table_head {
  background: #D9D9D9;
  position: sticky;
  top: 0px;

}

.fin_man_table_head th {
  padding: 10px;
  border: 1px solid white;
  text-align: center;
}

.fin_man_table_body {
  background-color: #FFFFFF;
}

.fin_man_table_body .table_row {
  border-top: 5px solid #F5F5F5;
  border-bottom: 5px solid #F5F5F5;
}

.fin_man_table_body tr td {
  padding: 10px;
}



.our_companies_table_container {
  width: 100%;
  max-height: 75%;
  overflow: auto;
  font-size: 14px;
}

.our_companies_table_container table {
  width: 100%;
}



.generate {
  display: none;
}

.table_text_right {
  text-align: end;
}

@media screen and (max-width:500px) {
  .generate {
    display: block;
  }

  .generate_invoice {
    display: none;
  }

  .fin_man_meter_search_bar {
    font-size: 14px;
  }

  .fin_man_meter_label {
    font-size: 14px;
  }

  .invoice_terms_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.invoice-type-div {
  min-width: (895px);
  height: (54px);
  padding: 0px, 6px, 0px, 6px;

}

.invoice-type-card {
  height: 50px;
  padding: 12px, 0px, 12px, 0px;
  border: 0px, 0px, 1px, 0px;
  gap: 10px;
  display: flex;
  justify-content: baseline;
  margin-top: -10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #878787;
  margin-right: 10px;
  align-items: end;
  margin-bottom: -10px;
}

.C-pointer {
  cursor: pointer;
}

.selected-invoice-type {
  border-bottom: 1px solid #000;
  color: #000;
  height: 30px;
}

.odd-row {
  background: var(--main-background, #E8E8E8) !important;
}

.even-row {
  background: var(--main-background, #ffffff) !important;
}

.invoice-heading {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.invoice-heading-card {
  color: var(--Grey-Shade, #878787);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 15px;
}

.invoice-heading-card span {
  font-weight: 600;
}

.invoice-select-date {
  /* background-color: transparent; */
  outline: none;
  font-size: 14px;
  /* border: 0px; */
  border: 1px solid var(--Textbox, #D3D3D3);
  height: 2.5rem;
  margin-top: -5px;
  caret-color: transparent;
  width: 13rem;
  border-radius: 8px;
}

/* financemanagerpayments styling */
.ptable_container {
  width: 100%;
  height: 470px;
  overflow: auto;
}

.ptable_container table {
  width: 100%;
  overflow: auto;
}

.noSpinArrows::-webkit-inner-spin-button,
.noSpinArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noSpinArrows {
  -moz-appearance: textfield;
  /* For Firefox */
}


.send-notification-icon {
  cursor: pointer;
  margin-left: 1px;
  height: 20px;
  width: 20px;

}




.meter_reports_screen_2 {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  ;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fin_man_screen_2_modal {
  width: 450px;
  height: 95%;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 15px;
}

.fin_man_screen_2_modal_header_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E3E3;
  padding: 5px;
}

.fin_man_screen_2_modal_header_section h2 {
  font-size: 25px;
  font-weight: 500;
  /* font: "Noto Sans Thai UI"; */
}

.topNavBar {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px 0px 0px;
  width: 100%;
  height: 10vh;
  background: #ffffff;
  border-bottom: 2px solid #e1e5e7;
  top: 0;
  position: fixed;
  z-index: 999;
  padding: 10px !important;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}

.topNavBarDev {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px 0px 0px;
  width: 100%;
  height: 10vh;
  background: rgba(18, 14, 255, 0.3);
  border-bottom: 2px solid #e1e5e7;
  top: 0;
  position: fixed;
  z-index: 999;
  padding: 10px !important;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}

.topNavBarQa {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px 0px 0px;
  width: 100%;
  height: 10vh;
  background: rgba(255, 165, 0, 0.3);
  border-bottom: 2px solid #e1e5e7;
  top: 0;
  position: fixed;
  z-index: 999;
  padding: 10px !important;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}

.topNavBarUat {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px 0px 0px;
  width: 100%;
  height: 10vh;
  background: rgba(205, 6, 255, 0.3);
  border-bottom: 2px solid #e1e5e7;
  top: 0;
  position: fixed;
  z-index: 999;
  padding: 10px !important;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}


.topNavItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.topNavFilters {
  display: flex;
  flex-direction: row;

}

.topNavIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  padding-right: 40px;
}

.topNavIcon {
  height: 32px;
  width: 32px;
}

.topNavdropdown {
  padding: 8px;
  padding-right: 80px;
  border-radius: 6px;
  border: 0.5px solid var(--Textbox, #D3D3D3);
  background: var(--color-gray-10, #FFF);

}


.menuIcon {
  height: 24px;
  width: 24px;
  margin: 10px;
}

.responsive_top_navbar {
  display: none;
  /* transition: top 10ms ease; */
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}

.left-navbar {
  padding: 0px;
  height: 87vh;
  width: 66px;
  left: 0px;
  position: fixed;
  background: #ffffff;
  z-index: 1000;
  border-right: 2px solid #eaeaea;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.leftNavItems {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  color: black;
}

.leftNavItems:hover {
  font-weight: 500;
}

.activeLeftNavItems {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  color: black;
  background-color: #d3d3d3;
  border-radius: 8px;
}

.left-navbar:hover {
  width: 16vw;
}

.iconText {
  display: none;
  margin-left: 20px;
  text-wrap: nowrap;
  line-height: 0px;
}

.left-navbar:hover .iconText {
  display: inline-block;
  text-decoration: none;
  line-height: 0px;

}

@media screen and (max-width: 768px) {
  .left-navbar {
    display: flex;
    bottom: 0 !important;
    position: fixed !important;
    /* left: 0; */
    width: 100%;
    height: 8vh;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    overflow: auto;
  }

  .iconText {
    display: none;
  }

  .left-navbar:hover {
    width: 100vw;
  }

  .left-navbar:hover .iconText {
    display: none;
  }


  .table_container_commercial_head {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 14px;
    /* margin-top: 50px; */
  }

  .table_container_commercial_head table {
    width: 100%;
  }


  .commercial-head-facilities-table {
    width: 100%;
    height: 53%;
    overflow: auto;
    font-size: 14px;
  }

  .commercial-head-facilities-table table {
    width: 100%;
  }

  .meter_reports_container {
    margin-left: 0px;
    height: 80vh;
    display: flex;
    flex-direction: column;

    /* gap: 10px; */
  }

  .meter_container_reports {
    margin-left: 0px;
    height: 110vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* gap: 10px; */
  }


  .commercial-head-add-facility-container {
    margin-left: 60px;
    margin-top: 10vh;
    padding: 20px;
    background-color: #F5F5F5;
    height: 87vh;
    display: flex;
    flex-direction: column;
    /* gap: 50px; */
  }


  .table_container_facility_manager {
    width: 100%;
    height: 42%;
    overflow: auto;
    font-size: 14px;
  }

  .table_container_facility_manager table {
    width: 100%;
  }

  .facility_container {
    margin-left: 0px;
    height: 97vh;
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
  }

  .table_container_finance_manager {
    width: 100%;
    height: 38%;
    overflow: auto;
    font-size: 14px;
  }

  .table_container_finance_manager table {
    width: 100%;
  }

  .invoice_container {
    margin-left: 0px;
    height: 130vh;
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
  }

  .meter_reports_table {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 14px;
  }

  .fin_meter_reports_table {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 14px;
  }


  .fm-dashboard-container {
    margin-left: 0px !important;
    padding-left: 20px !important;
    padding-bottom: 20px !important;
    height: 80vh !important;
    overflow: auto;
  }

  .fm-dashboard-invoive-graph {
    display: flex !important;
    width: 476px !important;
    height: 170px !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 24px !important;
    flex-shrink: 0 !important;
    margin-left: 0px !important;
  }

  .payments_container {
    margin-left: 0px;
  }

  .fm-dashboard-card-container {
    display: flex;
    flex-direction: column;
  }

  .fm-dashboard-graph-container {
    display: flex;
    flex-direction: column;
  }

  .fm-dashboard-graph-card {
    width: 95% !important;
    overflow: hidden;
  }

  .topNavBar {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px 0px 0px;
    width: 100%;
    height: 10vh;
    background: #ffffff;
    border-bottom: 2px solid #e1e5e7;
    top: 0;
    position: fixed;
    z-index: 999;
    padding: 10px !important;
    height: 10vh;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: 500ms;
  }

  .topNavBarDev {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px 0px 0px;
    width: 100%;
    height: 10vh;
    background: rgba(18, 14, 255, 0.3);
    border-bottom: 2px solid #e1e5e7;
    top: 0;
    position: fixed;
    z-index: 999;
    padding: 10px !important;
    height: 10vh;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: 500ms;
  }

  .topNavBarQa {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px 0px 0px;
    width: 100%;
    height: 10vh;
    background: rgba(255, 165, 0, 0.3);
    border-bottom: 2px solid #e1e5e7;
    top: 0;
    position: fixed;
    z-index: 999;
    padding: 10px !important;
    height: 10vh;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: 500ms;
  }

  .topNavBarUat {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px 0px 0px;
    width: 100%;
    height: 10vh;
    background: rgba(205, 6, 255, 0.3);
    border-bottom: 2px solid #e1e5e7;
    top: 0;
    position: fixed;
    z-index: 999;
    padding: 10px !important;
    height: 10vh;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: 500ms;
  }

  .responsive_top_navbar {
    display: block;
    position: absolute;
    top: 10vh;
    width: 100%;
    background-color: white;
    padding: 20px;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid #e1e5e7;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: 500ms;
  }

  .topNavFilters {
    display: none;

  }

  .topNavdropdown {
    width: 100%;
  }


  .topNavIcons {
    padding-right: 0px;
  }

  .commercial-head-add-facility-container {
    margin-left: 0px !important;
  }

  .technician-dashboard {
    margin-top: 10vh !important;
    margin-left: 0px !important;
    padding: 10px !important;
    height: 82vh !important;
  }

  .meter-readings {
    margin-top: 10vh;
    padding: 10px;
    margin-left: 0px !important;
  }

  .formbuilder-homepage {
    margin-left: 0px !important;
  }

  .form_builder_container {
    margin-left: 0px !important;
  }

  .users-list-homepage {
    margin-left: 0px !important;
  }

  .meterreadings_homepage {
    margin-left: 0px !important;
    min-height: 87vh;
  }

  .meterreadings_con {
    padding-bottom: 50px;
  }

  .set-password-container {
    margin-left: 0px !important;
  }

  .register-container {
    margin-left: 0px !important;
  }

  .addUserContainer {
    margin-left: 0px !important;
    margin-top: 10vh !important;
  }

  .partner-companies-container {
    margin-left: 0px !important;
    margin-top: 10vh !important;
    height: 80vh !important;
  }

  .checklist-container {
    margin-top: 10vh !important;
    margin-left: 0px !important;
    padding: 10px;
  }

  .tasks-screen-container {
    margin-left: 0px !important;
    margin-bottom: 10vh;
    overflow: auto;
    padding: 10px !important;
  }

  .technician-tasks-screen-container {
    margin-left: 0px !important;
    margin-bottom: 10vh;
    overflow: auto;
    padding: 10px !important;
  }

  .generate-invoices-container {
    margin-left: 0px !important;
    margin-top: 10vh !important;
    padding: 10px;
    height: 80vh;
  }
}


.license_fee_details_table {
  width: 100%;
  /* height: 400px; */
  overflow: auto;
  font-size: 14px;
  /* margin-top: 50px; */
}

.license_fee_details_table table {
  width: 100%;

}



.description-list {
  display: flex;
  flex-direction: column;
}

.modal_desc {
  clear: left;
  /* ensures dt starts on a new line */
  float: left;
  /* align dt to the left */
  width: 30%;
  margin-right: 20px;
  /* space between dt and dd */
  font-size: 16px;
  font-weight: 400;
  color: #706F6F;
}

.modal_details {
  clear: left;
  float: left;
  width: 100%;
  margin-right: 10px;
  font-size: 16px;
  font-weight: normal;
  color: #878787
}

.modal_detailsR {
  margin-left: 24px;
  font-size: 16px;
  color: #000000;
  font-weight: normal;
}

dd {
  float: right;
  /* align dd to the right */
  width: 60%;
  /* adjust the width as needed */
  color: #000000;
  font-weight: 500;
}

.modal_readings_box {
  border: 1px solid #878787;
  padding: 10px;
  width: 100px;
  height: 52px;
  border-radius: 5px;
}

.modal_body {
  height: 400px;
  overflow: auto;
}

.modal_popup {
  display: flex;
  width: 100%;
  /* height: 341px; */
  padding: 24px 24px 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  /* /overflow: auto; */
}

.modal_popupcard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 293px; */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 100px;
}

@media screen and (max-width:100px) {
  .modal_popupcard {
    display: flex;
    justify-content: center;
  }
}

.popupdetails_right {
  color: #000000;
  font-weight: normal;
  width: 43%;
  gap: 10px;
}

.popupdetails_right p {
  display: flex;
  justify-content: space-between;
}

.popupdetails_right input {
  width: 152px;
  height: 38px;
  padding: 4px 12px;
  padding-left: 30px;
  text-align: right;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  display: flex;
  text-overflow: ellipsis;
}

.popupdetails_right span {
  color: #878787;
}

.invoice_preview_container {
  background-color: #F5F5F5;
  height: 90vh;
  padding: 20px;
  margin-top: 10vh;
  font-size: 14px;
  padding-left: 25px;
}

.Rupee {
  position: relative;
}

.ruppee {
  position: absolute;
  top: 9px;
  left: 5px;
}

.invoice-preview-scrollable {
  height: 75vh;
  width: 76vw;
  overflow: auto;
}

@media screen and (min-height: 992px) and (max-height: 1200px) {
  .invoice-preview-scrollable {
    height: 80vh;
  }
}

@media screen and (min-height: 1201px) {
  .invoice-preview-scrollable {
    height: 80vh;
  }
}


.invoice-preview-card {
  display: flex;
  width: 100%;
  min-height: 1080px;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  background: var(--color-gray-10, #ffffff);
}

.invoice-preview-card-details {
  display: flex;
  width: 100%;
  /* height: 280px; */
  padding: 24px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  background: var(--Gray-0, #F9FAFC);
}

.invoice-preview-card-details-bottom {
  display: flex;
  width: 100%;
  /* height: 280px; */
  padding: 24px 36px;
  padding-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -50px;
  /* gap: 36px; */
  border-bottom: 0.5px solid var(--Gray-100, #D7DAE0);
  background: var(--Gray-0, #F9FAFC);
}

.invoice-preview-inner-card-2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.invoice-preview-inner-card {
  display: flex;
  /* width: 206px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 65%;
  flex-shrink: 0;
}



.invoice-preview-table-container {
  /* padding: 20px; */
  width: 97%;
  padding-top: 0px;
  /* margin-left: -40px; */
}

.invoice-preview-table thead th {
  background-color: #e6e6e6;
  border: 1px solid black;
  color: black;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  vertical-align: top;
}

.invoice-preview-table tbody th {
  color: #000;
  font-size: 10.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  border: 1px solid black;
}

.invoice-preview-table .total th {
  font-size: 16px;
  border: 0px;
  font-weight: bold;
}

.invoice-preview-total {
  display: flex;
  width: 35%;
  justify-content: space-between;
  align-items: center;
}

.invoice-preview-total-con {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.invoice-preview-total-con-left {
  width: 100%;
}

.invoice-preview-total-key {
  width: 110px;
  height: 28px;
  font-weight: bold;
  flex-shrink: 0;
  /* background-image: linear-gradient(90deg, #89BFF1 3.1%, rgba(137, 191, 241, 0.00) 108.26%); */
  /* clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%); */
  /* border: 1px solid black; */
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  /* padding-left: 2rem; */
  margin-top: -7px;
  vertical-align: center;
  text-align: start;
}

.invoice-preview-total-value {
  min-width: 110px;
  height: 28px;
  flex-shrink: 0;
  clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-right: 5px;
  padding-left: 20px;
  vertical-align: center;
  /* border: 1px solid #B2B2B2; */
  margin-left: -17px;
}

.invoice-preview-total-value span {
  width: 20px;
}

.invoice-preview-footer {
  display: flex;
  width: 100%;
  height: 109px;

  padding: 25px 39px 28px 37px;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid var(--Gray-100, #D7DAE0);
  background: var(--Gray-0, #F9FAFC);
  /* position: absolute; */
  bottom: 0;
}

.diagonal-line {
  border: 1px solid #B2B2B2;
  height: 28px;
  margin-top: -16px;
  margin-left: -16px;
  transform: skew(-38deg)
}

.back-button {
  margin-left: 6rem;
  margin-bottom: 1rem;
}

.back-button button {
  background-color: #fff;
  height: 40px;
  width: 80px;
  border: 1px solid #878787;
  font-size: large;
  font-weight: 400;
  color: #706F6F;
  border-radius: 8px;
}

.popup {
  display: flex;
  width: 949px;
  height: 341px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #89BFF1;
  gap: 24px;
  border-radius: 8px;
}


.calender-heading-container {
  display: flex;
  /* width: 1326px; */
  /* height: 90px; */
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  /* gap: 211px; */
  color: #878787;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -20px;
  overflow: hidden;
}

.calender-heading-card {
  display: flex;
  width: 190px;
  gap: 15px;
}

.calender-heading-container h1 {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-width: fit-content;
  margin-left: 60px;
}

.calender-selectedOption {
  color: #000;
}

.schedule-date {
  display: flex;
  width: 230px;
  height: 45px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--Textbox, #D3D3D3);
  background: var(--color-gray-10, #FFF);
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: -25px;
}

.schedule-date .heading {
  width: 180px;
  height: 30px;
  font-size: large;
  font-weight: 500;
  margin-top: 3px;
  border: 1px solid var(--Textbox, #fff);
  background: var(--color-gray-10, #FFF);
}

.schedule-date p {
  font-size: 30px;
  height: 40px;
  width: 40px;
  padding: 5px;
  text-align: center;
  vertical-align: center;
  padding-right: 0px;
  margin-top: 0px;
}

.month-container {
  display: flex;
  width: 100%;
  height: 450px;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
  position: relative;
  /* z-index: 1;   */
}

/* i.dates-container{
  position: relative;
  z-ndex: 1;
} */

.month-container-days {
  display: flex;
  justify-content: space-between;
  width: 97%;
  height: 50px;
  align-items: flex-start;
  background: #DEDEDE;
  gap: 0.5px;
}

.month-container-days tr {
  width: 183px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 0.672px solid #DEDEDE;
  background: #EAEAEA;
}

.month-container-days tr td {
  background-color: transparent;
  border: 0px;
}

.month-date-container {
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  height: 60vh;
  gap: 0px;
  align-items: flex-start;

  /* // for outside scrollbar */
  width: calc(97.5%);
  overflow-y: auto;
}

.month-date-container .card {
  width: 178px;
  display: flex;
  height: 95px;
  padding: 0px;
  flex-direction: column;
  flex-shrink: 0;
  border: 0.791px solid #DEDEDE;
  border-radius: 0px;
  border-collapse: collapse;
  background: var(--color-gray-10, #FFF);
  font-size: 15px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}

.month-date-container .preffix-month-card .card {
  padding: 5px;
}

.month-date-container .card .month-date-card {
  height: 100%;
}

.month-date-container .card .month-date-card p {
  margin: 0px;
}

.month-date-container .preffix-month-card p {
  margin: 0px;
}

.month-date-container .card .month-date-card .task-card {
  display: flex;
  width: 90%;
  height: 20px;
  padding: 3px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F5F5F5;
  border-left: 3px solid var(--Grey-1, #B2B2B2);
  color: #878787;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
}

.tasklist-taskname {
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.month-date-container .card .month-date-card .today-task-card {
  display: flex;
  width: 90%;
  height: 20px;
  padding: 3px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background: lightblue;
  border-left: 3px solid blue;
  color: #2e2e2e;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
}

.month-date-container .preffix-month-card .task-card {
  display: flex;
  width: 90%;
  height: 20px;
  padding: 3px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F5F5F5;
  border-left: 3px solid var(--Grey-1, #B2B2B2);
  color: #878787;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
}

.month-date-container .card .month-date-card .tasklist-extra {

  /* font-size: 12px; */
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 133.333% */
  margin-top: -8px;
  /* font-size: x-small; */
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  /* text-align: center; */
}

.month-date-container .card .month-date-card .tasklist-extra .round {
  background-color: #878787;
  min-height: 25px;
  min-width: 25px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 3px;
  border-top-left-radius: 50%;
  color: white;
  font-size: small;
  margin-top: -8px;
  margin-right: -5px;
  z-index: 100;

}

.month-date-container .preffix-month-card .tasklist-extra {
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 133.333% */
  margin-top: -8px;
  /* font-size: small; */
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.month-date-container .preffix-month-card .tasklist-extra .round {
  background-color: #878787;
  min-height: 25px;
  min-width: 25px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 3px;
  border-top-left-radius: 50%;
  color: white;
  font-size: small;
  margin-top: -8px;
  margin-right: -5px;
  z-index: 100;
}

.month-date-container .today-card {
  background: rgb(233, 240, 243);
  /* border: 1px solid #e7f0f8;
   */
  color: blue;
}

.month-date-container .preffix-month-card {
  color: #878787;
  opacity: 0.5;
}

.month-date-container .preffix-month-card .preffix-month-card-details {
  border: 0px;
}

.schedule-mail-popup {
  /* width: 60px !important; */
  /* height: 560px !important; */
  /* top: 50%;
  left: 50%; */
  /* padding: 24px; */
  border-radius: 8px;
  gap: 36px;
  overflow-y: auto;
  background-color: #fff;
  overflow: hidden;
}

.schedule-mail-popup .header h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  /* width: 410px; */
}

.schedule-mail-popup .schedule-mail-body {
  overflow-y: auto;
  max-height: 360px;
  width: calc(100% + 15px);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px
}

.schedule-mail-popup .schedule-mail-body .schedule-mail-details-con {
  width: 95%;
}

.schedule-mail-popup p {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #878787;
}

.schedule-mail-popup input {
  width: 100%;
  height: 52px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px;
}

.schedule-mail-popup input ::placeholder {
  color: #000;
}

.upload {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.schedule-type-con {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.schedule-type-con p {
  display: flex;
  width: 130px;
  height: 40px;
  padding: 0px 12px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  color: #1E1E1E;
}

.schedule-type-con .selected {
  background: #D3D3D3;
}

.schedule-custom-modal {
  width: 700px !important;
  transform: translate(-50%, -50%);
}

.schedule-type-day {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.schedule-type-day p {
  display: flex;
  width: 30px;
  height: 25px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  background: var(--color-gray-10, #FFF);
  color: #1E1E1E;
  text-align: center;
  justify-content: center;
}

.schedule-type-day .selected {
  background: #D3D3D3;
}

.schedule-popup-date {
  display: flex;
  width: 252px;
  height: 40px;
  padding: 0px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
}

.schedule-mail-pop-close {
  margin: 10px;
  /* margin-right: 25px; */
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.alarm {
  width: 24px;
  height: 24px;
  margin-top: 2px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: -2px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  top: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #9D9D9C;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.schedule-create-button {
  margin-top: 5px;
  width: 100%;
  display: flex;
  height: 50px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
  border-radius: 8px;
  background: #9D9D9C;
}

.per-day-events-body {
  padding: 5px;
  min-height: 40vh;
  max-height: 55vh;
  overflow-y: auto;
}

.per-day-events-body .list button {
  border: 0px;
  background-color: transparent;
  text-align: center;
  vertical-align: center;
  color: blue;
  text-decoration: underline;
}

.meter_reports_screen_2_container {
  background-color: #F5F5F5;
  min-height: 90vh;
  padding: 20px;
  margin-top: 60px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 5vw;
}

.draft_invoice_settings {
  background-color: #FFFFFF;
  height: 84vh;
  width: 24%;
  padding: 20px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow: auto;
}

.invoice_settings_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 90%;
  overflow: auto;
  padding-right: 5px;
}

.invoice_settings_bottom_section {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
  border-top: 1px solid lightgray;
}

::-webkit-scrollbar {
  width: 5.5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

.draft_container {
  height: 83vh;
  width: 74%;
}

.draft_invoice_preview {
  width: 100%;
  background-color: #FFFFFF;
  padding: 1px;
  overflow: auto;
  height: 90%;
}

.draft_invoice_preview_top_header {
  background-color: #F9FAFC;
  padding: 20px;
}

.draft_invoice_preview_header_logo {
  background-color: white;
  padding-right: 40px;
  margin-bottom: 20px;
}

.draft-invoice-preview-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
}

.draft-invoice-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: #F9FAFC;
  font-size: 10px;
}

.draft-invoice-preview-table {
  overflow: auto;
}

.draft-invoice-preview-table thead th {
  background-color: #e6e6e6;
  border: 1px solid #CDCDCD;
  ;
  color: #878787;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.draft-invoice-preview-table tbody th {
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 10px 0px;
}

.draft-invoice-preview-table .invoice-total th {
  font-size: 15px;
  border: 0px;
  font-weight: bold;
}

.draft_invoice_fin_man_date_range {
  border-radius: 6px;
  border: 0.01px solid var(--Grey-1, #B2B2B2);
  background: #FFF;
  font-size: 14px;
  padding: 5px;
  height: 35px;
  width: 120px;
}

.draft-invoice-header-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.invoice_settings_body label {
  font-size: 12px;
  padding: 0px 0px;
  color: #878787;
}

.invoice_settings_body input {
  border-radius: 6px;
}

.checkbox-input-field {
  height: 15px;
  width: 15px;
}

#checkbox-input-field-label {
  font-size: 16px;
  color: black;
}

#terms_and_conditions {
  font-size: 14px;
  color: black;
}

.draft-invoice-preview-inner-card {
  display: flex;
  width: 266px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.from_to_details {
  width: 130px;
}

@media screen and (max-width:1100px) {
  .meter_reports_screen_2_container {
    background-color: #F5F5F5;
    padding: 20px;
    margin-top: 60px;
    font-size: 14px;
    display: flex;
    gap: 20px;
    margin-left: 5vw;
  }

  .draft_invoice_settings {
    width: 100%;
    height: fit-content;
    max-width: 400px;
    margin: auto;
  }

  .draft_container {
    width: 100%;
    height: fit-content;
  }
}

.fm-dashboard-container {
  background-color: #F5F5F5;
  font-size: 12px;
  padding-left: 50px;
  margin-left: 36px;
  height: 87vh;
  margin-top: 10vh;
}

.fm-dashboard-heading {
  /* margin-top: 40px; */
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.fm-dashboard-card-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}

.fm-dashboard-card {
  width: 300px;
  height: 120px;
  padding: 16px 24px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 18px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.fm-dashboard-innercard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.fm-dashboard-innercard h1 {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background-color: var(--Light-Grey, #EAEAEA);
  border: 1px solid #D9D9D9;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fm-dashboard-innercard p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fm-dashboard-innercard-details {
  display: flex;
  gap: 10px;
}

.fm-dashboard-innercard-details div {
  gap: 3px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fm-dashboard-innercard-details span {
  color: #706F6F;
}

.fm-dashboard-innercard-details p {
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.fm-dashboard-graph-container {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
}

.fm-dashboard-graph-card {
  width: 47%;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--color-gray-10, #FFF);
}

.commercial-head-dashboard-graph-card {
  width: 47%;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--color-gray-10, #FFF);
}

.fm-dashboard-invoice-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}

.fm-dashboard-invoice-heading h1 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.fm-dashboard-invoice-heading select {
  width: 120px;
  height: 30px;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  background: var(--color-gray-10, #FFF);
}

.fm-dashboard-invoive-graph {
  display: flex;
  width: 350px;
  height: 220px;
  align-items: center;
  justify-content: flex-center;
  gap: 24px;
  flex-shrink: 0;
  margin-left: 20px;
}

.fm-dashboard-payments-graph {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  /* flex-shrink: 0; */
}

.calender_container {
  background-color: #F5F5F5;
  min-height: 90vh;
  width: calc(100vw);
  /* padding: 20px; */
  margin-top: 10vh;
  font-size: 14px;
  overflow: hidden;
  /* padding-left: 40px; */
}

.calendar_container_div {
  padding: 20px;
  padding-left: 30px;
  height: 87vh;
}

.week-view {
  width: 100%;
}

.week-view-container {
  display: flex;
  width: 100%;
  height: 450px;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
  /* margin-top: 1rem; */
}

.week-days-con {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background-color: #FFF;
  /* padding-top: 10px; */
  border-bottom: 0.5px solid grey;
}

.week-con {
  flex-grow: 2;
  overflow-y: hidden;
}

.week-con-each-day {
  display: flex;
  margin: -15px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  border: 1px solid #DEDEDE;
  border-top: 0px;
  border-bottom: 0px;
}

.week-day-timeHeader {
  display: flex;
  width: 103px;
  height: 93px;
  /* margin:-15px 0px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  /* flex-shrink: 0; */
  border-right: 1px solid #DEDEDE;
  flex-grow: 1;
}

.week-con-each-day .highlight {
  background-color: #9D9D9C;
  color: #FFF;
  margin-top: -10px;
  border-radius: 50%;
  font-size: large;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.week-con-each-day .highlight-today {
  background-color: rgb(12, 179, 235);
  color: #FFF;
  margin-top: -10px;
  border-radius: 50%;
  font-size: large;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fin_man_day_calendar_container {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  height: 67vh;
  overflow: auto;
  margin: auto;
  margin-top: 20px;
}

.time {
  background-color: white;
  width: 150px;
  padding: 20px;
  border: 1px solid lightgray;
}

.task {
  width: 950px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid lightgray;
}

.task-details {
  background-color: #EDEDED;
  height: 50px;
  width: 90%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border-left: 4px solid #929294;
}

.task-add {
  width: 950px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  border: 1px solid lightgray;
}

.button_add_schedule {
  background-color: #EDEDED;
  border: none;
  padding: 5px 40px;
  border-radius: 6px;
  color: #878787;
}

.day_calendar_top_header {
  position: sticky;
  top: 0px;
}

@media screen and (max-width:1000px) {
  .task {
    width: 500px;
  }

  .task-add {
    width: 500px;
  }
}

@media screen and (max-width:750px) {
  .task {
    width: 300px;
  }

  .task-add {
    width: 300px;
  }

  .task-details {
    width: 100%;
  }
}

@media screen and (max-width:560px) {
  .time {
    background-color: white;
    width: 100px;
    padding: 15px;
  }

  .task {
    width: 270px;
    padding-left: 10px;
  }

  .task-add {
    width: 270px;
    padding-left: 10px;
  }
}

.week-body-con {
  height: 62vh;
  background-color: #FFF;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 5px);

}

.week-body {
  display: flex;
  width: 100%;
  height: 80px;

}

.week-body-each-day {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px;

}

.week-body-each {

  width: calc(50%);
  padding: 5px;
  height: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #DEDEDE;
}

.week-body-time {
  width: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #DEDEDE;
}

.hour-line {
  position: absolute;
  width: 100%;
  /* color: red; */
  margin-left: -5px;
  border: 1px solid red;
}

.week-body-each-task {
  display: flex;
  width: 100%;
  height: 25px;
  align-items: flex-start;
  padding-top: 3px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F5F5F5;
  border-left: 3px solid #878787;
  margin-bottom: 3px;

}

.week-body-each-task p {
  margin-left: 3px;
}

.tasklist-extra {

  /* font-size: 12px; */
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 133.333% */
  margin-top: -8px;
  /* font-size: x-small; */
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  /* text-align: center; */
}

.tasklist-extra .round {
  background-color: #878787;
  min-height: 25px;
  min-width: 25px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 3px;
  border-top-left-radius: 50%;
  color: white;
  font-size: small;
  margin-top: -5px;
  z-index: 100;

}

.task-details {
  width: 100%;
}



.tab-title {
  color: black;
}

.tab-title .active {
  color: white;
  background-color: grey;
}

.current-view-options {
  display: flex;
  gap: 20px;
}

.current-view-options-sm {
  display: none;
}

.mobile-view-month-year {
  display: none;
}

.schedule-date-sm {
  display: none;
}

.month-view-medium {
  display: none;
}

.pre-month-view-medium-suf {
  display: block;
}

@media screen and (max-width:490px) {
  .calender_container {
    padding-left: 0px;
  }

  .calender-heading-container h1 {
    margin-left: 10px;
    display: none;
  }

  .schedule-date {
    display: none;
  }

  .calender-heading-card {
    display: none;
  }

  .current-view-options {
    display: none;
  }

  .current-view-options-sm {
    display: block;
    margin-top: -7px;
  }

  .current-view-options-sm {
    background-color: #9D9D9C;
    color: #fff;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    padding: 5px;
    outline: none;
  }

  .week-view-container {
    margin-left: -15px;
    margin-top: 0rem;
    width: 113%;
  }

  .week-body-con {
    height: 63vh;

  }

  .week-day-timeHeader {
    width: 50.5px;
    height: 100%;
    border: 1px solid #fff;
    border-right: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
  }

  .week-body-time {
    width: 3.7rem;
    font-size: x-small;
    padding-top: 1rem;
  }

  .week-body-each {
    padding: 0px;
    padding-top: 3px;
    /* width: 42px; */
    width: calc(14.3%);
  }

  .week-body-each-task {
    width: 80%;
    font-size: smaller;
    margin-left: 2px;
  }

  .week-body-each-task p {
    font-size: smaller;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tasklist-extra {
    margin-top: -5px;
    width: 90%;

  }

  .tasklist-extra .round {
    min-height: 20px;
    min-width: 20px;
    font-size: smaller;
    margin-top: 0px;
    margin-right: 0px;
    z-index: 100;
  }

  .task-details {
    width: 100%;
  }

  .hour-line {
    margin-left: 0px;
  }

  .week-con-each-day .highlight {
    width: 30px;
    height: 30px;
    font-size: medium;
    overflow: hidden;
  }

  .week-con-each-day .highlight-today {
    font-size: medium;
    width: 30px;
    height: 30px;
  }

  .week-days-con:after {
    content: "";
    clear: both;
    display: table;
  }

  .week-con-each-day {
    width: calc(99.9%);
    overflow: hidden;
  }

  .mobile-view-month-year {
    display: block;
    /* margin-top: -10px; */
    margin-bottom: -10px;
    text-align: start;
    margin-left: 10px;

    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    min-width: fit-content;
  }

  .schedule-date-sm {
    display: block;
    background-color: white;
    top: 80vh;
    left: 80vw;
    position: absolute;
    z-index: 2;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: #9D9D9C 0px 6px 15px -6px;
  }

  .schedule-date-sm p {
    margin-top: -4px;
    font-size: 50px;
    color: blue;
  }

  .schedule-mail-popup {
    /* width: 50vw; */
  }

  .schedule-mail-popup-modal {
    /* width: 95vw !important; */
  }

  .schedule-type-con p {
    width: 90px;
  }

  .schedule-type-day p {
    display: flex;
    width: 35px;
    font-size: small;
    height: 40px;
    padding: 0px 4px;
    align-items: center;

  }

  .schedule-mail-inputs input {
    width: 135px !important;
    font-size: small;
  }

  .schedule-mail-pop-close {
    margin-right: 7px;
  }

  .month-container {
    margin-left: -15px;
    width: 100vw;
    /* height:40vh;  */
  }

  .month-table {
    table-layout: fixed;
    width: 98vw !important;
  }

  .month-container-days tr td {
    font-size: small;
    content: attr(data-short);
    display: inline-block;
    width: 6.0ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .month-date-container .card {
    width: 14.2%;
    height: 7.56rem;
  }

  .month-date-container {
    width: calc(98.5% + 2px);
  }

  .month-date-container .preffix-month-card .card {
    width: 14.2%;
    height: 7.56rem;
  }

  .month-date-container .card .month-date-card .tasklist-extra .round {
    margin-top: 28px;
  }

  .month-date-container .preffix-month-card .tasklist-extra .round {
    margin-top: 28px;
  }
}


@media screen and (min-width :500px) and (max-width:1050px) {
  .schedule-date {
    display: none;
  }

  .schedule-date-sm {
    display: block;
    background-color: white;
    top: 89vh;
    left: 85vw;
    position: absolute;
    z-index: 2;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    box-shadow: #9D9D9C 0px 6px 15px -6px;
  }

  .schedule-date-sm p {
    margin-top: -3px;
    font-size: 60px;
    color: blue;
  }

  .week-view-container {
    height: 80vh;
    margin-left: 18px;
  }

  .week-body-con {
    height: 75vh;
    background-color: #FFF;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 5px);
  }

  .week-day-timeHeader {
    width: 97.5px;
    height: 100%;
    border: 1px solid #fff;
    border-right: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
  }

  .month-container {
    margin-left: 10px;
  }

  .month-table {
    table-layout: fixed;
    width: 92vw !important;
  }

  .month-container-days tr td {
    font-size: small;
    content: attr(data-short);
    display: inline-block;
    /* width: .0ch;  */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .month-date-container .card {
    width: 14.2%;
    height: 15.56rem;
  }

  .month-date-container {
    width: 98.2%;
    height: 78vh;
  }

  .month-date-container .preffix-month-card .card {
    width: 14.2%;
    height: 7.56rem;
  }

  .month-date-container .card .month-date-card .tasklist-extra .round {
    margin-top: 155px;
  }

  .month-date-container .preffix-month-card .tasklist-extra .round {
    margin-top: 155px;
  }

  /* .month-view-non-medium {
    display: none;
  }

  .pre-month-view-non-medium {
    display: none;
  } */

  .month-view-medium {
    display: block;
  }

  .pre-month-view-medium {
    display: block;
  }

  .pre-month-view-medium-suf {
    display: block;
  }

  .month-date-container .card .month-date-card .tasklist-extra-md {

    /* font-size: 12px; */
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 133.333% */
    margin-top: -8px;
    /* font-size: x-small; */
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
    /* text-align: center; */
  }

  .month-date-container .card .month-date-card .tasklist-extra-md .round {
    background-color: #878787;
    min-height: 25px;
    min-width: 25px;
    padding-top: 5px;
    padding-left: 1px;
    padding-right: 3px;
    border-top-left-radius: 50%;
    color: white;
    font-size: small;
    margin-top: 35px;
    margin-right: -2px;
    z-index: 100;

  }

  .month-date-container .preffix-month-card .round {
    background-color: #878787;
    min-height: 25px;
    min-width: 25px;
    padding-top: 5px;
    padding-left: 4px;
    padding-right: 3px;
    border-top-left-radius: 50%;
    color: white;
    font-size: small;
    margin-top: 28px;
    margin-right: -2px;
    z-index: 100;
    margin-left: 60px;

  }

  .month-date-container .suffic-month-card .round {
    background-color: #878787;
    min-height: 25px;
    min-width: 25px;
    padding-top: 5px;
    padding-left: 4px;
    padding-right: 3px;
    border-top-left-radius: 50%;
    color: white;
    font-size: small;
    margin-top: 28px;
    margin-right: -2px;
    z-index: 100;
    margin-left: 60px;

  }


}

.detailsList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.detailsItem {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  width: 50%;
}


.add-facility-button {
  border-radius: 8px;
  background: var(--Grey-1, #B2B2B2);
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.add-facility-button-our-companies {
  border-radius: 8px;
  background: var(--Grey-1, #B2B2B2);
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.commercial-head-dropdown {
  padding: 7px 60px 7px 10px;
  border-radius: 6px;
  width: 250px;
  border: 0.5px solid #9D9D9C;
  background-color: var(--color-gray-10, #FFF);
}

.add-facility-input-fields {
  width: 250px !important;
  border-radius: 6px;
  border: 0.5px solid #9D9D9C !important;
  text-overflow: ellipsis;
}

.responsive-add-facility-input-fields {
  width: 250px !important;
  border-radius: 6px;
  border: 0.5px solid #9D9D9C !important;
  text-overflow: ellipsis;
}

@media screen and (max-width : 990px) {
  .commercial-head-dropdown {
    padding: 4px 60px 4px 10px;
    border-radius: 6px;
    width: 150px;
    border: 0.5px solid #9D9D9C;
    background-color: var(--color-gray-10, #FFF);
  }

  .d-flex {
    display: flex;
  }

  .gap-3 {
    gap: 1rem;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .search-container {
    margin-left: auto;
  }

  .search-bar {
    padding: 7px 10px;
    border-radius: 6px;
    width: 250px;
    border: 0.5px solid #9D9D9C;
    background-color: var(--color-gray-10, #FFF);
  }

  .add-facility-input-fields {
    width: 150px !important;
    border-radius: 6px;
    border: 0.5px solid #9D9D9C !important;
  }

  .responsive-add-facility-input-fields {
    width: 150px !important;
    border-radius: 6px;
    border: 0.5px solid #9D9D9C !important;
  }

}

@media screen and (min-width : 990px) {
  .add-facility-modal-label-address {
    margin-right: 55px;
  }
}

@media screen and (max-width : 500px) {
  .commercial-head-dropdown {
    padding: 4px 10px 4px 10px;
    border-radius: 6px;
    width: 100px;
    border: 0.5px solid #9D9D9C;
    background-color: var(--color-gray-10, #FFF);
  }

  .add-facility-input-fields {
    width: 100px !important;
    border-radius: 6px;
    border: 0.5px solid #9D9D9C !important;
    text-overflow: ellipsis !important;
  }

  .responsive-add-facility-input-fields {
    width: 250px !important;
    border-radius: 6px;
    border: 0.5px solid #9D9D9C !important;
    text-overflow: ellipsis !important;
  }
}

@media screen and (max-width : 377px) {
  .add-facility-modal-label {
    font-size: 12px;
  }

  .add-facility-modal-label-address {
    font-size: 12px;
  }
}

.facility-area-input {
  position: relative;
  width: fit-content;
}

.square-feet {
  position: absolute;
  right: 10px;
  top: 6px;
  font-weight: 660;
}

.add-facility-electricity-rate {
  position: relative;
  width: fit-content;
}

.add-facility-water-rate {
  position: relative;
  width: fit-content;
}

.add-facility-electricity-rate img {
  position: absolute;
  top: 10px;
  left: 10px;
}

.add-facility-water-rate img {
  position: absolute;
  top: 10px;
  left: 10px;
}


.commercial-das-users-con {
  width: 42rem;
  height: 17rem;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--color-gray-10, #FFF);
}

.commercial-das-contracts-con {
  width: 42rem;
  height: 13rem;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--color-gray-10, #FFF);
}

.commercial-das-graph-con {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 78vh;
}

.commercial-das-graph-con-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 78vh;
}

.commercial-dashboard-con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.commercial-das-spaces-con {
  width: 35rem;
  height: 15rem;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 12px;
  background: var(--color-gray-10, #FFF);
}

.commercial-das-pending-con {
  width: 35rem;
  height: 15rem;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--color-gray-10, #FFF);
}

.commercial-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  padding-bottom: 5px;
}

.commercial-header h1 {
  color: #000;

  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.commercial-header select {
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  outline: none;
  height: 2rem;
  margin-top: 3px;
  margin-left: 10px !important;
}

.commercial-header button {
  color: #878787;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0px;
  background-color: transparent;
}

.ch-dashboard-invoive-graph {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-shrink: 0;
}

.commercial-user-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
}

.commercial-user-table Table tbody {
  display: block;
  height: 10rem;
  overflow: auto;

}

.commercial-user-table thead {
  color: #000;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.commercial-user-table .commercial-das-thead,
.commercial-das-tbody .commercial-das-tr {
  display: table;
  width: 100%;
  table-layout: fixed;

}

.commercial-user-table .commercial-das-tbody .commercial-das-tr {
  height: 2.5em;
  flex-shrink: 0;
  border-bottom: 1px solid var(--Light-Grey, #EAEAEA);
  color: #878787;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.commercial-user-table .commercial-das-thead {
  width: calc(100% - 1em)
}

.invoice-preview-con {
  overflow-x: hidden;
  overflow-y: hidden;
  gap: 15px;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;

}

.fm-invoice-setting-con {
  width: 20vw;
  background: var(--color-gray-10, #FFF);
  height: 76vh;
  padding: 15px;
  /* padding-left: 15px; */

  overflow: auto;
}

.invoice-setting-header {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.fm-invoice-setting-con p {
  color: #878787;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fm-invoice-setting-con .bill-to {
  color: #878787;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fm-invoice-setting-con span {

  font-size: 9px;

}

.fm-invoice-settings-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 10px;
}

.fm-invoice-settings-div p {
  font-size: 15px;
}

.fm-invoice-setting-con button {
  display: flex;
  width: 100%;
  height: 2.5rem;
  color: var(--color-gray-10, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
  border-radius: 8px;
  background: #9D9D9C;
  margin-bottom: 5px;
}

.fm-invoice-setting-con select {
  display: flex;
  width: 98%;
  height: 38px;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  margin-top: -5px;
  margin-bottom: 9px;
}

.fm-invoice-billto {
  color: #000 !important;
  margin-top: -5px;

}

.invoice-bar-code {
  width: 200px;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  align-items: center;
  font-size: xx-large;
}

.invoice-preview-logo {
  margin-top: 110px;
}

.invoice-user-notes {
  color: #626161;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: -3px;
}

.invoice-user-notes-div {
  width: 100%;
  overflow: hidden;
}

.invoice-preview-details {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.invoice-preview-details span {
  color: #706F6F;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
}

.invoice-preview-inner-card-para p {
  width: 26vw;
  margin-bottom: -1px;
}

.invoice-preview-inner-card-para {
  margin-top: -17px;
}

/* .invoice-preview */
.invoice-preview-inner-card-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-content: center; */
  /* gap: 10vw; */
}

.invoice-preview-total-section {

  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: flex-end;
  align-items: flex-end;
  /* align-items: center; */
  gap: 0px;
  font-size: small;

}

.invoice-preview-total-section p {
  height: 10px;
  margin-right: 10px;
}

.invoice-preview-buttons {
  padding: 13px;
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 20px;
  margin-left: 50px;
  width: 96%;
  background: #fff;
  border: 1px solid #acacac;
}

.invoice-preview-buttons button {
  border-radius: 8px;
  background: #9D9D9C;
  display: flex;
  width: 10rem;
  height: 2.7rem;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
  color: #fff;
}

.chat-feature {
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  color: #000;
}

.chatbox {
  border: 1px solid #8F8F8F;
  padding: 5px;
  margin-bottom: 10px;
}

.chat-user {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.chat-user .name {
  font-size: large;
}

.chat-feature span {
  color: #017EFA;
}

.invoice-payment-terms-con {
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
}

.invoice-payment-terms {
  /* border: 1px solid #8F8F8F;
  padding: 5px; */
  margin-bottom: 10px;
}

.invoice-payment-terms .heading {
  font-size: large;
  text-decoration: underline;
}

@media screen and (max-width : 490px) {
  .invoice-preview-con {
    display: flex;
    flex-direction: column;
  }

  .fm-invoice-setting-con {
    width: 90vw;
  }

  .invoice-preview-con {
    margin-left: 0px;
  }

  .invoice_preview_container {
    height: auto;
  }

  .invoice-preview-buttons {
    margin-bottom: 50px;
    margin-left: 0px;
    width: 100%;
  }

  .invoice-preview-scrollable {
    width: 100%;
  }

  .invoice-preview-card {
    width: 1263px;
  }

  .chat-feature {
    width: 1263px;
  }

  .invoice-payment-terms-con {
    width: 1263px;
  }

}

@media screen and (min-width :500px) and (max-width:1050px) {
  .invoice_preview_container {
    margin-top: 10vh;

    /* height: auto; */
  }

  .fm-invoice-setting-con {
    height: 81vh;
    width: 40vw;
  }

  .invoice-preview-scrollable {
    height: 81vh;
    /* width: 1236px; */
  }

  .invoice-preview-card {
    width: 1263px;
  }

  .chat-feature {
    width: 1263px;
  }

  .invoice-payment-terms-con {
    width: 1263px;
  }

  .invoice-preview-buttons {
    width: 94%;
  }
}

/* create contract css mobile */
@media (max-width : 767px) {
  .newContractContainer {
    min-height: 87vh;
    /* padding:10px; */
    padding: 5px 10px;
    margin-top: 10vh;
    /* margin-left: 60px; */
    display: flex;
    flex-direction: column;

  }

  .newAssetContainer {
    min-height: 87vh;
    /* padding:10px; */
    padding: 5px 10px;
    margin-top: 10vh;
    margin-bottom: 20px;
    /* margin-left: 60px; */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

  }

  .stepsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    /* border: 1px solid #acacac; */
    border-radius: 5px 0px 0px 5px;
    margin-bottom: 20px;
  }

  .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-between;
  }

  .listOfCards {
    display: flex;
    flex-direction: column;
    margin: 10px;
    /* height: 76vh; */
    overflow-y: auto;
  }

  .infoFotter {
    background-color: #EAEAEA;
    height: 10vh;
    /* z-index: 9999; */
    margin-bottom: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 0px 0px 5px 0px;

  }

  .inputsSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }

  .responsiveInputSection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }

}

/* Create a contract css  desktop*/

@media (min-width : 768px) {
  .newContractContainer {
    height: 87vh;
    padding: 5px 10px;
    margin-top: 10vh;
    margin-left: 60px;
    display: flex;
    flex-direction: row;

  }

  .newAssetContainer {
    height: 87vh;
    padding: 5px 10px;
    margin-top: 10vh;
    margin-bottom: 20px;
    margin-left: 60px;
    display: flex;
    flex-direction: row;

  }

  .addUserContainer {
    height: 87.5vh;
    padding: 5px 10px;
    margin-top: 10vh;
    margin-left: 60px;
    display: flex;
    flex-direction: row;

  }

  .stepsContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #cccccc;
    border-radius: 5px 0px 0px 5px;
  }

  .infoContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    justify-content: space-between;
  }

  .listOfCards {
    display: flex;
    flex-direction: column;
    margin: 10px;
    height: 76vh;
    overflow-y: auto;
  }

  .infoFotter {
    background-color: #EAEAEA;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 0px 0px 5px 0px;
  }

  .inputsSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

  }

  .responsiveInputSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
}





.stepsTitle {
  width: 100%;
  background-color: #EAEAEA;
  border-radius: 5px 0px 0px 0px;
  border-bottom: 1px solid #cccccc;
}

.infoTitle {
  width: 100%;
  background-color: #EAEAEA;
  border-radius: 0px 5px 0px 0px;
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.stepsCard {
  background-color: #EAEAEA;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.stepsCardActive {
  background-color: #EAEAEA;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 2px solid #a5a5a5;
  border-radius: 5px;
}

.infoBody {
  height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}


.infoNextBtn {
  border-radius: 8px;
  background: #9D9D9C;
  display: flex;
  /* width: 100%; */
  height: 2.7rem;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
  color: #fff;
}

.infoBodyInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.checkboxHolder {
  display: flex;
}

.inputCheckbox {
  padding: 10px;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}


.meter-readings {
  margin-top: 10vh;
  padding: 10px;
  margin-left: 60px;
}

.meter-reading-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 0.5px solid #9D9D9C;
}

.meter-reading-heading h1 {
  color: var(--Black, #000);

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.meter-reading-heading button {
  width: 32px;
  height: 33px;
  font-size: larger;
  border: 0px;
  font-weight: bold;
  background-color: transparent;

}

.meter-reading-scrollable {
  margin-top: 10px;
}

.meter-reading-inner-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
}

.meter-reading-inner-div p {
  color: var(--Field-Name, #706F6F);
  padding-top: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.meter-reading-inner-div select {
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  outline: none;
}

.meter-reading-inner-div input {
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  height: 40px;
  width: 63%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
}

.meter-reading-inner-div-reading input {
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  height: 40px;
  width: 35%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.meter-reading-inner-div label {
  margin-top: 10px;
}

.meter-reading-spaces {
  display: flex;
  width: 65%;
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  height: 40px;

}

.meter-reading-spaces input {
  border: 0px;
  height: 38px;
  outline: none;
  flex-grow: 1;
}

.meter-reading-spaces p {
  padding-right: 5px;

}

.meter-reading-time-stamp {
  color: var(--Field-Name, #706F6F);
  margin-top: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: -5px;
}

.meter-readings-upload {
  color: var(--Grey-Shade, #878787);
  margin-top: -10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.meter-readings-photo-div {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 7rem;
  border-radius: 6px;
  border: 2px dashed #D9D9D9;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meter-reading-body {
  overflow-y: auto;
  height: 77vh;
  width: calc(100% + 5px);
  padding: 5px;
}

.meter-readings-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  gap: 15px;
  margin-bottom: 50px;
}

.meter-readings-save {
  display: flex;
  width: 102px;
  height: 2.5rem;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #9D9D9C;
  border: 0px;
  color: white;
  font-size: large;
}

.meter-readings-cancel {
  display: flex;
  width: 102px;
  height: 2.5rem;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: large;

  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #9D9D9C;
}

.show-camera-div {
  width: 100%;
  margin-top: -28px;
  height: 300px;
}

.show-camera {
  min-height: 260px;

}

.show-camera-div-img {
  height: 244px;
  width: 100%;
  margin-bottom: 35px;
}

.show-camera-button {
  border-radius: 8px;
  background: #9D9D9C;
  display: flex;
  width: 90%;
  height: 37px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 0px;
}

.show-camera-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.meter-reading-units-warning {
  color: #000;
  text-align: justify;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.3;
  background: #D9D9D9;
}

.fm-invoice-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* width:100vw; */
}

.fm-invoice-con-filters {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: -10px;
  flex-wrap: wrap;
}

.fin_man_billing_period {
  display: flex;
  flex-direction: column;
  gap: -10px;
  margin-top: -17px;
}

.fm-invoice-heading-con {
  display: flex;
  align-items: center;
  gap: 30px;
}


.fin-meter-reports-con {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  width: 100vw;
}

.fm-invoice-select-type {
  display: none;
}

.fm-invoice-search-con {
  display: none;
}

@media screen and (max-width : 490px) {
  .fm-invoice-heading-con {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 0px;
  }

  .meter-readings-heading-con {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 0px;
  }


  .fin-meter-reports-con {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 0px;
    margin-top: -20px;
    flex: wrap;
    width: 100vw;
  }

  .meter_reports_containe {
    width: 100vw;
  }

  .fin_man_meter_search_bar_container {
    display: block;
  }

  .our_companies_search_bar_container {
    display: block;
    width: 100%;
  }

  .add-facility-button-our-companies {
    width: 100%;
  }

  .our_companies_search_bar_container .fin_man_meter_search_bar {
    width: 100%;
  }

  .fm-invoice-search-con {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

  }

  .fm-invoice-search-bar {
    border-radius: 8px;
    border: 1px solid var(--Textbox, #D3D3D3);
    background: #FFF;
    display: flex;
    width: 50%;
    height: 2.5rem;
    /* padding: 12px 16px; */
    align-items: center;
    gap: 10px;
  }

  .fm-invoice-search-bar input {
    background-color: transparent;
    border: 0px;
    width: 80%;
  }

  .fm-invoice-select-type {
    display: block;
    height: 2.5rem;
    border-radius: 12px;
    border: 1px solid var(--Textbox, #D3D3D3);
    outline: none;
  }

  .invoice-select-date {
    outline: none;
  }

  .slf-preview-table-con {
    width: 99%;
    overflow: auto;
  }

}

.technician-dashboard {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 15px 15px 0px 15px !important;
  background-color: #F5F5F5;
  height: 87vh;
  overflow: auto;
}

.technician-dashboard .technician-header {
  font-size: 30px;
  font-weight: 600;
}

.technician-notification-container {
  background-color: #FFFFFF;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.water-notification-icon {
  background-color: #878787;
  width: fit-content;
  border-radius: 50%;
  padding: 5px;
}

.water-notification-icon img {
  width: 25px;
  height: 25px;
}

.technician-dashboard-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  gap: 5px;
  padding: 10px;
  text-decoration: none;
  color: #000000;
}

.technician-dashboard-cards .dashboard-card img {
  height: 40px;
  width: 40px;
}

.link-tags {
  color: #000000;
  text-decoration: none;
}

@media screen and (max-width : 560px) {
  .technician-dashboard .technician-header {
    font-size: 22px;
    font-weight: 600;
  }

  .technician-dashboard h5 {
    font-size: medium;
    font-weight: 400;
  }

  .notification-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width : 560px) {
  .technician-dashboard .technician-header {
    font-size: 22px;
    font-weight: 600;
  }

  .technician-dashboard h5 {
    font-size: medium;
    font-weight: 400;
  }

  .notification-content {
    font-size: 13px;
  }

}

@media screen and (min-width : 750px) {
  .technician-dashboard-cards {
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    flex-wrap: wrap !important;
    margin-top: 20px !important;
  }

  .dashboard-card {
    width: 49% !important;
    height: 140px !important;
    text-decoration: none;
    color: #000000;
  }
}

.slf-details-preview-con {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slf-details-preview-con button {
  display: flex;
  width: 35% !important;
  height: 2.5rem;
  color: var(--color-gray-10, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
  border-radius: 8px;
  background: #9D9D9C;
  margin-bottom: 5px;
}

.slf-details-preview-con .slf-details-button {
  display: flex;
  width: 63% !important;
  height: 2.5rem;
  color: #9D9D9C;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #9D9D9C;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 5px;
}

.slf-preview-modal {
  /* width: 100%; */
}

.slf-preivew-table {
  /* width: 50vw; */

  background-color: #fff;
}

.slf-preivew-table thead tr {

  background: var(--Textbox, #D3D3D3);
  display: flex;
  align-items: center;
  /* gap: 12px; */

}

.slf-preivew-table thead th {
  border-right: 1px solid var(--Light-Grey, #EAEAEA);
  background: var(--Textbox, #D3D3D3);
  /* padding: 18px 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 6rem;
  height: 2rem;
}

.slf-preivew-table tbody {
  overflow: auto;
  height: 7rem;
  background-color: #fff;
}

.slf-preivew-table tbody tr {
  display: flex;
  align-items: center;
  background-color: #EAEAEA;

}

.slf-preivew-table tbody tr th {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 6rem;
  height: 2rem;
  background-color: #EAEAEA;
  border-right: 1px solid var(--Light-Grey, #fff);
}

@media screen and (max-width:499px) {
  .commercial-dashboard-con {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .commercial-das-graph-con {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
  }

  .commercial-das-graph-con-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
  }

  .commercial-das-users-con {
    width: 95%;
    height: 17rem;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--color-gray-10, #FFF);
    /* overflow: auto; */
  }

  .commercial-user-table {
    width: 42rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
  }

  .commercial-user-table-scrollable {
    overflow: auto;
  }

  .commercial-das-contracts-con {
    width: 95%;
    height: 13rem;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--color-gray-10, #FFF);
  }

  .commercial-header h1 {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .commercial-header select {
    display: flex;
    padding: 3px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid var(--Textbox, #D3D3D3);
    outline: none;
    height: 2rem;
    margin-top: 0px;
  }

  .commercial-header button {
    color: #878787;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0px;
    background-color: transparent;
  }

  .commercial-user-table .commercial-das-tbody .commercial-das-tr {
    height: 2.5em;
    flex-shrink: 0;
    border-bottom: 1px solid var(--Light-Grey, #EAEAEA);
    color: #878787;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .commercial-user-table thead {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .commercial-das-spaces-con {
    width: 95%;
    height: 20rem;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--color-gray-10, #FFF);
  }

  .commercial-das-pending-con {
    width: 95%;
    height: 18rem;
  }

  .fm-dashboard-graph-card {

    height: 18em;
  }

  .commercial-das-no-space button {
    margin-left: 0rem;
  }
}

@media screen and (min-width :500px) and (max-width:1050px) {
  .commercial-dashboard-con {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .commercial-das-graph-con {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
  }

  .commercial-das-graph-con-2 {
    display: flex;
    width: 97%;
    flex-direction: row;
    overflow: hidden;
    gap: -20px;
    height: auto;
  }

  .commercial-das-users-con {
    width: 97%;
    height: 17rem;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--color-gray-10, #FFF);
    /* overflow: auto; */
  }

  .commercial-das-contracts-con {
    width: 97%;
    height: 17rem;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--color-gray-10, #FFF);
  }

  .commercial-das-spaces-con {
    width: 48%;
    height: 19rem;
  }

  .commercial-das-pending-con {
    width: 48%;
    margin-left: -10px;
    height: 19rem;
  }

  .commercial-das-contract-line-chart {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  .fm-dashboard-payments-graph {
    /* margin-left: -10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

.commercial-das-no-user {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commercial-das-no-user button {
  border-radius: 8px;
  color: #fff;
  width: 10rem;
  height: 3rem;
  font-size: medium;
  padding: 5px;
  outline: none;
  border: 0px;
  background: var(--Grey-Shade, #878787);
}

.commercial-das-no-user .spaces {
  padding: 10px;
  width: 35rem;
  margin-left: 100px;
}

.slf-details-preview-con {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slf-details-preview-con button {
  display: flex;
  width: 35% !important;
  height: 2.5rem;
  color: var(--color-gray-10, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
  border-radius: 8px;
  background: #9D9D9C;
  margin-bottom: 5px;
}

.slf-details-preview-con .slf-details-button {
  display: flex;
  width: 63% !important;
  height: 2.5rem;
  color: #9D9D9C;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #9D9D9C;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 5px;
}

.slf-preview-modal {
  /* width: 100%; */
}

.slf-preivew-table {
  /* width: 50vw; */

  background-color: #fff;
}

.slf-preivew-table thead tr {

  background: var(--Textbox, #D3D3D3);
  display: flex;
  align-items: center;
  /* gap: 12px; */

}

.slf-preivew-table thead th {
  border-right: 1px solid var(--Light-Grey, #EAEAEA);
  background: var(--Textbox, #D3D3D3);
  /* padding: 18px 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 6rem;
  height: 2rem;
}

.slf-preivew-table tbody {
  overflow: auto;
  height: 7rem;
  background-color: #fff;
}

.slf-preivew-table tbody tr {
  display: flex;
  align-items: center;
  background-color: #EAEAEA;

}

.slf-preivew-table tbody tr th {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 6rem;
  height: 2rem;
  background-color: #EAEAEA;
  border-right: 1px solid var(--Light-Grey, #fff);
}

.form_builder_container {
  height: 100vh;
  /* margin-left: 65px; */
  /* margin-top: 10vh; */
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  background-color: #F5F5F5;
}

.form_builder_rightside_container {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
}

.form_preview {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: space-between;
  padding: 10px 10px 0px 20px;
  height: 90%;
  overflow: hidden;
  position: relative;
  /* width: 210mm; */
}

.form_builder {
  background-color: white;
  width: 20%;
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
}

.element-settings {
  background-color: white;
  width: 30%;
  margin-left: 10px;
  padding: 20px;
  overflow: auto;
}

.form_builder_panel_top {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-right: 5px;
  padding-bottom: 20px;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}

.form-builder-elements {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px 10px 20px 10px;
  position: relative;
  border-radius: 6px;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 500ms;
}

.form_builder_input_field {
  height: 30px;
}

.minimize_arrows {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.delete_element {
  position: absolute;
  top: 10px;
  right: 10px;
}

.form_table_container {
  width: 100%;
  border-radius: 8px;
  overflow: auto;
  border: 0.4px solid #D3D3D3;
  margin-top: 20px;
}

.form_table_header {
  background-color: #E6EAF3;
  border-radius: 12px;
  font-weight: 500;
}

.form_table_header td {
  padding: 8px;
}

.row_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.row-item {
  flex: 0 0 calc(33.33% - 10px);
}

.form-elements {
  border-radius: 6px;
  border: 1px solid #D3D3D3;
  padding: 10px 12px;
  cursor: move;
}

.form-builder-tabs {
  width: 100%;
  text-align: center;
  border: 0.4px solid lightgray;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.activeTab {
  cursor: pointer;
  background-color: #D3D3D3;
}

.preview-button-form-builder {
  position: absolute;
  width: 90%;
  top: 0px;
  display: flex;
  justify-content: center;
}

.preview-button-form-builder button {
  background-color: lightgray;
  border: none;
  padding: 6px 15px;
  border-radius: 6px;
}

.delete-radio-option:hover {
  background-color: lightgray;
  height: 20px;
  width: 20px;
}

.formbuilder-homepage {
  background-color: #F5F5F5;
  margin-top: 10vh;
  margin-left: 60px;
  padding: 15px;
  height: 90vh;
  overflow: auto;
}

.formbuilder-tab {
  padding: 15px 25px;
  cursor: pointer;
}

.formbuiler-activeTab {
  background-color: lightgray;
}

.element-conteiner {
  position: relative;
}

.delete-icon {
  position: absolute;
  right: -10px;
  top: 5px;
}

.delete-icon :hover {
  color: red;
}

.form_preview_container {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.form_view {
  width: 95%;
  max-width: 210mm;
  background-color: white;
  height: 90vh;
  overflow: auto;
  padding: 20px;
  position: relative;
}

.close-form {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
}

.commercial-das-no-space {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 35px; */
  margin-top: -20px;

}

.commercial-das-no-space button {
  border-radius: 8px;
  color: #fff;
  width: 10rem;
  height: 3rem;
  font-size: medium;
  padding: 5px;
  outline: none;
  border: 0px;
  background: var(--Grey-Shade, #878787);
  margin-left: 10rem;
}

.commercial-das-no-user-space {
  width: 35rem;
  height: 10rem;
  display: flex;
  padding: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.commercial-das-no-user-space .spaces {
  width: 90%;
}

@media screen and (max-width:499px) {
  .commercial-das-no-space button {
    margin-left: -7.5rem;
    margin-top: 5rem;
  }
}

@media screen and (min-width :500px) and (max-width:1050px) {
  .commercial-das-no-space button {
    margin-left: 0rem;
    margin-top: 0rem;
  }

  .commercial-das-no-user-space .spaces {
    width: 70%;
    margin-top: 5rem;
    padding: 30px;
  }
}

.input-error-text {
  color: red;
  font-size: small;
}

.input-error-text-1 {
  color: green;
  font-size: small;
}

.login-con {
  overflow: hidden;
  padding: 10px;
  display: flex;
  /* background-color: red; */
}

.login-container {
  display: flex;

}

.register-container {
  margin-top: 10vh;
  margin-left: 60px;
  height: 90vh;
  display: flex;
  /* background-color: red; */
  padding: 20px;
}

.login-image {
  width: 50vw;
  height: 100vh;
  overflow: hidden;
}

.login-div {
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-heading {
  color: #000;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}


.login-input {
  margin-top: 25px;
  width: 25rem;
  height: 3rem;
  display: flex;
  padding-left: 10px;
  justify-content: start;
  align-items: center;
  border: 1px solid #000;
  border-radius: 10px;
}

.login-input input {
  outline: none;
  margin-left: 5px;
  margin-top: 2px;
  border: 0px;
  width: 90%;
}

.login-input-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.forgot-password {
  color: #706F6F;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-left: 10px;
}

.login-terms {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.login-terms input {
  margin-right: 8px;
  flex-shrink: 0;
}

.login-terms a {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}

@media (max-width: 768px) {
  .login-terms {
    align-items: flex-start;
    position: relative;
    padding-left: 24px;
  }

  .login-terms::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
  }

  .login-terms a,
  .login-terms span {
    display: inline-block;
    margin-left: 0;
    text-indent: -16px;
    padding-left: 16px;
  }
}


.login-button {
  border-radius: 8px;
  border: 1px solid #9D9D9C;
  background: #9D9D9C;
  width: 23rem;
  height: 2.5rem;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-button:disabled {
  opacity: 0.5;
}

@media screen and (max-width: 499px) {
  .login-image {
    display: none;
  }

  .login-div {
    width: 100vw;
  }

  .login-input {
    width: 20rem;
  }

  .login-button {
    width: 18rem !important;
  }

  .login-terms {
    margin-left: 13px;
  }
}

.login-con {
  margin-top: 10vh;
  margin-left: 5vw;
  overflow: hidden;
  padding: 10px;
  display: flex;

}

.login-image {
  width: 50vw;
  height: 100vh;
  overflow: hidden;
}

.login-div {
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-heading {
  color: #000;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login-input {
  margin-top: 25px;
  width: 25rem;
  height: 3rem;
  display: flex;
  padding-left: 10px;
  justify-content: start;
  align-items: center;
  border: 2px solid #cccccc;
  border-radius: 5px;
}

.login-input input {
  outline: none;
  margin-left: 5px;
  border: 0px;
  width: 90%;
}

.login-icon {
  opacity: 40%;
}

.lock {
  height: 1.4rem;
  width: 1.4rem;
  margin-left: -2px;
}

.login-input-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.forgot-password {
  color: #706F6F;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-left: 10px;
}



.login-button {
  border-radius: 8px;
  border: 1px solid #9D9D9C;
  background: #9D9D9C;
  width: 25rem;
  height: 3rem;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.login-button:disabled {
  border-radius: 8px;
  border: 1px solid #9D9D9C;
  background-color: #D3D3D3;
  width: 25rem;
  height: 3rem;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



@media screen and (max-width: 499px) {
  .login-image {
    display: none;
  }

  .login-div {
    width: 100vw;
    height: 100vh;
    background-image: url('../public/images/icons/loginimage.jpg');
    background-size: cover;
  }

  .login-div-sm {
    background-color: #fff;
    padding: 5px;
    /* margin-left: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    border-radius: 8px;
    width: 90%;
  }

  .login-input {
    width: 20rem;
  }

  .login-button {
    width: 20rem;
  }

  .login-terms {
    margin-left: 13px;
    text-align: center;
    margin-left: -3px;
    font-size: small;
  }

  .login-heading {
    font-size: 55px;
  }
}

@media screen and (min-width :500px) and (max-width:1050px) {
  .login-image {
    display: none;
  }

  .login-div {
    width: 100vw;
  }

  .login-div {
    width: 100vw;
    height: 100vh;
    background-image: url('../public/images/icons/loginimage.jpg');
    background-size: cover;
  }

  .login-div-sm {
    background-color: #fff;
    padding: 5px;
    /* margin-left: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    border-radius: 8px;
  }

}

.error {
  color: red;
}

.new-facility-file-names {
  /* display: flex; */
  flex-wrap: wrap;
  gap: 10px;
  color: green;
}

.file-tick {
  width: 20px;
  height: 30px;
  margin-right: 5px;
  margin-top: -3px;
}

.popper-modal {
  display: inline-flex;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* gap: 20px; */
}

.secondary {
  display: flex;
  width: 45%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: auto;


}

.secondary-left {
  display: flex;
  width: 45%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: #000000 !important;
  border: 1px solid #EAEAEA !important;
  background-color: #F8FAFD !important;


}

.footer {
  justify-content: space-between !important;
}

.submit-modal {
  min-width: fit-content;
  display: inline-flex;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.text-center {
  padding-top: 100px;
}

.invalid-input {
  font-size: 14px;
  color: red;
}

.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin-left: -30px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  height: 3rem;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.commercial-head-table {
  z-index: 100;
}

.action-items {
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: start;
}

.action-items img {
  margin-top: -15px;
  height: 15px;
  width: 15px;
}

.action-item-pop {
  margin-bottom: -15px;
}

.view-icon {
  margin-left: -4px;
}

.view-icon p {
  margin-left: -3px;
}

.view-icon img {

  height: 23px;
  width: 23px;
}

.commercial-head-table img {
  height: 18px;
}

.confirmation {
  display: flex;
  width: 45%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
  color: #000000 !important;
  border: 1px solid #EAEAEA !important;
  background-color: #F8FAFD !important;
}

.confirmation-left {
  display: flex;
  width: 45%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.meter-reading-header {
  color: var(--Field-Name, #706F6F);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.meter-reading-header2 {
  color: var(--Field-Name, #706F6F);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.meter-reading-thead {
  color: #5F5F5F;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.meter-reading-thead td {
  border: 1px solid #ACABAB;
  /* border-right: 1px solid #FFF !important; */
  background: #EDEDED !important;

}

.meter-reading-tbody {
  border-collapse: collapse;
}

.meter-reading-tbody td {
  border: 1px solid #ACABAB;
}

.meter-reading-tbody .meter-reading {
  display: flex;
  justify-content: end;
}

.meter-reading-body .meter-reading-select {
  margin-left: 5px;
  border: 0px;
  outline: none;
  border-left: 1px solid #D9D9D9;
}

.meter-reading-table {
  margin-top: 10px;
  margin-bottom: 5px;
}

.dg-reading-toggle {
  color: var(--Grey-Shade, #878787);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.close-icon {
  height: 20px;
  margin-right: -25px;
  margin-left: 10px;
}

.table-meter-name {
  border: 0px;
  width: 90%;
  outline: none;
}

.table-meter-reading {
  border: 0px;
  width: 60%;
  margin-right: 3px;
  text-align: end;
  outline: none;
}

.form-loader-container {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10000000000;
}

.loader-formbuilder {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.572);
  /* Transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.loader-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  /* Transparent white spinner border */
  border-top: 4px solid #fff;
  /* White spinner head */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  color: white;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 16px;
}

.users-list-homepage {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 20px;
  height: 87vh;
  background-color: #F5F5F5;
}

.set-password-container {
  padding: 20px;
  /* background-image: url("../public/images/icons/SetPasswordBackground.avif"); */
  background-size: cover;
  height: 100vh;
  overflow: auto;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invoice-type-font {
  font-size: medium;
}

.custom-select {
  height: 30px;
  border: 1px solid #aaa;
  cursor: pointer;
}

.custom-options {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #aaa;
}

.custom-options div {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

.secondary-success {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-right: auto;


}

.invoice-logo-placeholder {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: -30px;
}

.invoice-header-address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  width: 60%;
  /* Adjust according to the size of the A4 paper */
  max-width: 70%;
}


.signature {
  display: flex;
  justify-content: space-between;
  align-items: right;
  gap: 10px;
  margin-bottom: 30px;
  border: #000;
  width: 90%;
  font-weight: bolder;
  margin-top: 50px;
}

.invoice-address-right {
  display: flex;
  flex-direction: row;
  line-height: 14px;

}

.invoice-address-right p {
  margin-bottom: 7px;
}

.fac_man_table_body {
  background-color: #FFFFFF;
  /* text-align: center; */
}

.fac_man_table_body .table_row {
  border-top: 5px solid #F5F5F5;
  border-bottom: 5px solid #F5F5F5;
}

.fac_man_table_body tr td {
  padding: 10px;
}

.fac-man-date-picker {
  outline: none;
  font-size: 14px;
  border: 1px solid var(--Textbox, #D3D3D3);
  margin-top: -5px;
  caret-color: transparent;
  width: 13rem;
  border-radius: 8px;
  /* padding-left: 15px;
  padding-right: 10px; */
  padding: 12px;
  background: var(--color-gray-10, #FFF);
}

.fac-man-date-picker-container {
  position: relative;
  z-index: 1000;
  /* padding: 10px; */
}

.invoice-address-right {
  display: flex;
  flex-direction: row;
  line-height: 14px;
}

.profile-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid gray;
  font-size: large;
  font-weight: bold;
}

.profile-relative-container {
  position: relative;
}

.profile-container {
  position: absolute;
  top: 10px;
  right: -20px;
  z-index: 1000000px;
  height: 200px;
  min-width: 300px;
  padding: 0px 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #B2B2B2;
}



.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
  position: relative;
  margin-bottom: -40px;
  margin-left: 8px;
}

.triangle::before,
.triangle::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 22px solid #B2B2B2;
}

.triangle::before {
  top: 1px;
  left: -11px;
}

.triangle::after {
  top: -1px;
  left: -10px;
}

.alert-error-icon {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  color: red;
}

.admin-controls-container {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 20px;
  background-color: #F5F5F5;
  min-height: 90vh;
}

/* CustomDatePicker.css */
.react-datepicker__header {
  display: none;
}

.react-datepicker__month-container {
  padding-top: 0;
}

.react-datepicker__navigation {
  display: none;
}

.admin-controls-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 500px;
}

.admin-controls-date-picker {
  /* background-color: transparent; */
  outline: none;
  font-size: 14px;
  /* border: 0px; */
  border: 1px solid var(--Textbox, #D3D3D3);
  height: 2.5rem;
  margin-top: -5px;
  caret-color: transparent;
  width: 16rem;
  border-radius: 8px;
}

.partner-companies-container {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 20px;
  background-color: #F5F5F5;
  height: 87vh;
}

.rejected-text {
  color: red;
  /* font-size: 14px; */
  font-weight: 500;
}

.no-hover-outline:hover {
  background-color: inherit !important;
  /* border-color: inherit !important; */
  color: inherit !important;
}

.invoice_terms_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.environment-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.green {
  background-color: green;
}

.orange {
  background-color: orange;
}

.purple {
  background-color: purple;
}

.blue {
  background-color: blue;
}

.environment-indicator-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: auto;
}

.env-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
}

.env-bar.green {
  background-color: rgba(0, 128, 0, 0.3);
  color: green;
}

.env-bar.orange {
  background-color: rgba(255, 165, 0, 0.3);
  color: orange;
}

.env-bar.purple {
  background-color: rgba(205, 6, 255, 0.3);
  color: purple;
}

.env-bar.blue {
  background-color: rgba(18, 14, 255, 0.3);
  color: blue;
}

.footerbottom {
  background-color: white;
  height: 3vh;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border: 2px solid #e1e5e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  /* border-top: #000; */
}

.footerbottomDev {
  background-color: rgba(18, 14, 255, 0.3);
  height: 3vh;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border: 2px solid #e1e5e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  /* border-top: #000; */
}

.footerbottomQa {
  background-color: rgba(255, 165, 0, 0.3);
  height: 3vh;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border: 2px solid #e1e5e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  /* border-top: #000; */
}

.footerbottomUat {
  background-color: rgba(205, 6, 255, 0.3);
  height: 3vh;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border: 2px solid #e1e5e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  /* border-top: #000; */
}

.foter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*  */
  height: 3vh;
}

.foter-left {
  display: flex;
  align-items: center;
}

.foter-left a {
  text-decoration: underline;
  color: #000;
  margin-right: 10px;
  font-size: 0.8em;
}

.foter-right {
  /* font-size: 0.8em;
  white-space: nowrap;
  margin-left: auto;
  margin-top: auto; */
  display: flex;
  align-items: center;

}

.foter-right p {
  /* text-decoration: underline; */
  color: #000;
  /* margin-right: 10px; */
  font-size: 0.8em;
  margin-bottom: 0px !important;
  /* margin-top: 15px;  */
}

.invoice-left-data {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.invoice-left-data .key {
  width: 30% !important;
}

.invoice-left-data .value {
  width: 70% !important;
  text-align: start;
}

/* Define keyframe animation */
@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

/* Define class for the animation */
.click-animation {
  animation: clickAnimation 0.2s ease-in-out;
}

.misc-invoice-tbody input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  padding-left: 5px;
}

.misc-invoice-tbody select {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  /* padding-left: 5px; */
}

.animate-drop {
  animation: drop 0.5s ease-in-out;
}

@keyframes drop {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.checklist-container {
  margin-top: 10vh;
  margin-left: 66px;
  padding: 20px;
  height: 90vh;
  background-color: #f5f5f5;
}

.button-list {
  position: relative;
}

.list-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0px;
  right: 0px;
}

.list-items div {
  padding: 5px;
  cursor: pointer;
}

.list-items div:hover {
  background-color: #ddd;
}

.assetTabs {
  border-bottom: 1px solid #D3D3D3;
  border-right: 1px solid #D3D3D3;
  border-left: 1px solid #D3D3D3;
  border-radius: 0px 0px 8px 8px;
}

/* Ensure the parent container is responsive */
.responsive-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}


.invoice-header-title {
  font-size: clamp(16px, 2vw, 40px);
  white-space: nowrap;
  overflow: hidden;
}


.meter-report-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}


@media (min-width: 769px) {
  .meter-report-row {
    margin-bottom: 8px;
  }
}

.meter-readings-heading-con {
  display: flex;
  align-items: right;
  gap: 10px;
}

.meter-report-col {
  flex: 0 1 48%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.meter-report-col:last-child {
  margin-right: 0;
}

.label {
  font-weight: 500;
  color: #000;
  margin-right: 16px;
  width: 150px;
}

.meter-input-field {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .meter-report-row {
    flex-direction: column;
  }

  .meter-report-col {
    flex: 1;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .label {
    margin-bottom: 4px;
    width: auto;
  }

  .meter-input-field {
    max-width: 100%;
  }
}

.table_container {
  width: 100%;
  max-height: 72%;
  overflow: auto;
  font-size: 14px;
}

.table_container table {
  width: 100%;
}

.userstable_container {
  width: 100%;
  max-height: 290px;
  overflow: auto;
  font-size: 14px;
}

.userstable_container table {
  width: 100%;
}

.table-containerTC {
  max-height: 35vh;
  overflow: auto;
}

@media (max-width: 768px) {
  .table-containerTC {
    margin-bottom: 20px;
  }
}

.meterreports_filterdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  padding: 10px;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  flex-grow: 1;
}

.date-range-inputs {
  display: flex;
  gap: 10px;
}

.date-input {
  flex-basis: 150px;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.invoice_btn {
  color: white;
  cursor: pointer;
}

.add-reading-button {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: var(--Grey-1, #B2B2B2);
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.add-reading-button img {
  margin-right: 5px;

}

@media (max-width: 768px) {
  .meterreports_filterdiv {
    justify-content: center;
    gap: 10px;
  }

  .filter-section {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .date-range-inputs {
    flex-direction: row;
    width: 100%;
  }

  .button-group {
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }

  .add-reading-button {
    justify-content: center;
    width: 100%;
  }
}

.meterreadings_homepage {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 20px;
  min-height: 87vh;
  background-color: #F5F5F5;
}

.tasks-screen-container {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.technician-tasks-screen-container {
  margin-top: 10vh;
  margin-left: 60px;
  padding: 20px;
  background-color: #F5F5F5;
  height: 90vh;
}

.tasks-header-items-container {
  border-right: 10px solid #FFFFFF;
}

.tasks-header-items {
  background-color: #DEDEDE;
  padding: 10px;
  min-width: 300px;
  box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.25);
}

.tasks-body-items {
  border-right: 10px solid #FFFFFF;
  padding: 10px;
  min-width: 300px;
  height: 300px;
  vertical-align: top;
  border-radius: 6px;
  background: #E8E9E9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.arrow {
  transition: transform 0.2s ease-in-out;
}

.arrow-right {
  transform: rotate(0deg);
}

.arrow-down {
  transform: rotate(90deg);
}

.task-item {
  background-color: #FFFFFF;
  min-height: 100px;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0.5px solid #8F8F8F;
}

.scheduler-dropdown {
  height: 40px;
  outline: none;
  border: none;
  background-color: #878787;
  padding: 0px 10px;
  color: white;
  border-radius: 6px;
}

.worklog-dropdown {
  height: 40px;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
}

.worklog-dropdown option {
  color: black;
}

.worklog-assignee {
  width: 40px;
  height: 40px;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.worklog-assignee:hover {
  z-index: 1000;
}

.selected-worklog-assignee {
  border: 3px solid #85B8FF;
}

.remaining-assignee {
  position: relative;
}

.remaining-assignee .remaining-assignee-list {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 1000;
  background-color: #FFFFFF;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-list {
  padding: 7px 60px 7px 10px;
  border-radius: 6px;
  width: 250px;
  border: 0.5px solid #9D9D9C;
  background-color: var(--color-gray-10, #FFF);
  text-overflow: ellipsis;
}

.meter-reading-inner-div1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: auto;
}

.meter-reading-inner-div1 p {
  color: var(--Field-Name, #706F6F);
  padding-top: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.meter-reading-inner-div1 select {
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  outline: none;
}

.meter-reading-inner-div1 input {
  border-radius: 6px;
  border: 1px solid var(--Textbox, #D3D3D3);
  height: 40px;
  width: 63%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
}


.userstable_container {
  width: 100%;
  max-height: 72%;
  overflow: auto;
  font-size: 14px;
}

.userstable_container table {
  width: 100%;
}


.container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.box {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #333;
  text-align: center;
}

.level {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.summary-name {
  width: 100%;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
}

.summary-count {
  width: 100%;
  padding: 10px;
  font-size: 24px;
}

.summary-body {
  padding: 10px;
}

/* calendar css */
.schedule-event-dropdown {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #D9D9D9
}

/* asset dropdown css */
.schedule-event-dropdown.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.recurring {
  margin-left: 10%;
}

.searchable-dropdown {
  height: 52px !important;
  width: 250px;
  /* border:red !important; */
  position: relative;
  z-index: 1;
}

.searchable-dropdown2 {
  height: 52px !important;
  /* width: 450px; */
  min-width: 250px;
  max-width: 550px;
  /* border:red !important; */
  position: relative;
  z-index: 1;
}

.legend {
  display: flex;
  align-items: center;
  gap: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.legend-square {
  width: 10px;
  height: 10px;
}

.category-1 {
  background-color: #264653;
}

.category-2 {
  background-color: #2A9D8F;
}

.category-3 {
  background-color: #E9C46A;
}

.category-4 {
  background-color: #F4A261;
}

.category-5 {
  background-color: #E76F51;
}

.task-boxes {
  width: 27px;
  height: 27px;
  border: #000;
  /* background-color: #F4A261; */
  color: #fff;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;

}

.comment-save-button {
  background-color: #a1a6ab;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 15px;
}

.comment-cancel-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 15px;
  border: 1px solid lightgray;
}

.set-reminder {
  height: 30px;
  width: 150px !important;
  border-radius: 6px;
  border: 0.5px solid #9D9D9C !important;
  text-overflow: ellipsis !important;
}

.set-reminder-input {
  height: 30px !important;
  width: 50px !important;
  border-radius: 6px;
  border: 0.5px solid #9D9D9C !important;
  text-overflow: ellipsis !important;
}

.responsive-set-reminder {
  height: 30px !important;
  width: 100px !important;
  border-radius: 6px;
  border: 0.5px solid #9D9D9C !important;
  text-overflow: ellipsis !important;
}

.generate-invoices-container {
  margin-top: 10vh;
  margin-left: 66px;
  padding: 10px;
  background-color: #F5F5F5;
  height: 87vh;
}

.custom-date-picker {
  position: relative;
  z-index: 1000;
  /* Adjust the z-index as needed */
}